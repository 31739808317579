<template>
  <!-- 新增企业页面 -->
  <div class="addCooperativeEnterprise page-info-content">
    <!-- 内容输入框 -->
    <el-form ref="ruleForm" label-position="left" :model="ruleForm" :rules="rules" :inline="true" label-width="130px" class="demo-ruleForm form-item-row page-form">
      <el-form-item label="合作企业">
        <el-input v-model="ruleForm.partnerName" disabled placeholder="合作企业" />
      </el-form-item>
      <el-form-item prop="plantType" label="企业性质">
        <el-select v-model="ruleForm.plantType" remote placeholder="请选择企业性质">
          <el-option
            v-for="(item) in $store.getters.getDictionaryItem('AgentSupplierInfoNature')"
            :key="item.dictId"
            :label="item.dictName"
            :value="item.dictId"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="firmAddress" label="所在地区">
        <el-cascader
          v-model="ruleForm.firmAddress"
          :props="{ label: 'name', value: 'name', children: 'children' }"
          :options="allCityOption"
          @change="getFirmCity"
        />
      </el-form-item>
      <el-form-item prop="partnerAddr" label="详细地址">
        <el-input v-model="ruleForm.partnerAddr" placeholder="请先选择详细地址" />
      </el-form-item>
      <el-form-item prop="contacts" label="联系人">
        <el-input v-model="ruleForm.contacts" placeholder="请输入联系人" />
      </el-form-item>
      <el-form-item prop="contactPhone" label="联系电话">
        <el-input v-model="ruleForm.contactPhone" placeholder="请输入联系电话" />
      </el-form-item>
      <el-form-item label="合作协议">
        <FileUpload :edit-file-name="ruleForm.cooContractName" @getFile="getCooAgrFile" />
      </el-form-item>
      <el-form-item prop="otherFilePath" label="其他材料">
        <FileUpload :edit-file-name="ruleForm.otherFileName" @getFile="getOthMaterialFile" />
      </el-form-item>
      <el-form-item prop="agentSupplierPaymentConfigList" label="保证金配置(锁量)">
        <div class="list-operation">
          <el-button size="small" type="primary" icon="el-icon-plus" @click="addPaymentConfig" />
        </div>
        <Table :item-data="itemData" :list-data="ruleForm.agentSupplierPaymentConfigList">
          <template #paymentDate="{ row,index }">
            <el-input v-model="row.paymentDate" type="number" placeholder="请输入" @change="inputPaymentDate(row,index)" />
          </template>
          <template #paymentRate="{ row }">
            <div class="row">
              <el-input v-model="row.paymentRate" type="number" placeholder="请输入" /><p>%</p>
            </div>
          </template>
          <template #deleteRow="{ index, row }">
            <i class="icons el-icon-remove-outline" @click="deleteRow(index,row)" />
          </template>
        </Table>
      </el-form-item>
      <el-form-item prop="settleDate" label="结算日期">
        <div class="row">
          <p>每月</p>
          <el-input v-model="ruleForm.settleDate" type="number" placeholder="请输入结算日期" @input="getSettleDate" />
          号
        </div>
      </el-form-item>
      <el-form-item prop="deliverWarehouseId" label="交收仓库">
        <el-select
          v-model="ruleForm.deliverWarehouseId"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="querySearch"
          @change="getdeliverWarehouseName"
        >
          <el-option
            v-for="item in warehouseListArr"
            :key="item.id"
            :label="item.warehouseName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="inventoryType" label="仓库等级">
        <el-select v-model="ruleForm.inventoryType" remote placeholder="请输入仓库等级">
          <el-option
            v-for="(item) in $store.getters.getDictionaryItem('warehouseLevel')"
            :key="item.dictId"
            :label="item.dictName"
            :value="item.dictId"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="deliveryType" label="仓库属性">
        <el-select v-model="ruleForm.deliveryType" remote placeholder="请输入仓库属性">
          <el-option
            v-for="(item) in $store.getters.getDictionaryItem('warehouseProp')"
            :key="item.dictId"
            :label="item.dictName"
            :value="item.dictId"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="allAddress" label="省市区">
        <el-cascader
          v-model="ruleForm.allAddress"
          :props="{ label: 'name', value: 'name', children: 'children' }"
          :options="allCityOption"
          @change="getCity"
        />
      </el-form-item>
      <el-form-item prop="deliveryAddr" label="详细地址">
        <el-input v-model="ruleForm.deliveryAddr" placeholder="请输入详细地址" />
      </el-form-item>
      <!-- <el-form-item prop="partnerTemplateId" label="上游协议模板">
        <el-select v-model="ruleForm.partnerTemplateId" filterable placeholder="请选择上游协议模板">
          <el-option
            v-for="val in custTemplateArr1"
            :key="val.compactId"
            :label="val.compactName"
            :value="val.compactId"
          />
        </el-select>
      </el-form-item> -->
      <el-form-item prop="custTemplateId" label="下游协议模板">
        <el-select v-model="ruleForm.custTemplateId" filterable placeholder="请选择下游协议模板">
          <el-option
            v-for="val in custTemplateArr2"
            :key="val.compactId"
            :label="val.compactName"
            :value="val.compactId"
          />
        </el-select>
      </el-form-item>
      <!-- 代采商品列表 -->
      <!-- <el-form-item label="代采商品列表" class="spread-over table-item">
        <div class="list-operation">
          <el-button size="small" type="primary" icon="el-icon-plus" @click="addGoodsConfig" />
        </div>
        <div class="tableBox">
          <table class="table">
            <tr>
              <td>品名</td>
              <td>材质</td>
              <td>规格</td>
              <td>产地</td>
              <td>件重</td>
              <td>操作</td>
            </tr>
            <tr v-for="(item, inde) in ruleForm.agentSupplierGoodsList" :key="inde">
              <td> -->
      <!-- <el-select v-model="item.goodsId" filterable placeholder="请选择品名" @change="getPnameId(item, inde)">
                <el-option v-for="val in pnameArr" :key="val.code" :label="val.goodsName" :value="val.code" />
              </el-select> -->
      <!-- {{ item.goodsName }}
              </td>
              <td>
                {{ item.goodsMaterials }} -->
      <!-- <el-input v-model="item.goodsMaterials" placeholder="选择品名联动" /> -->
      <!-- </td>
              <td>
                {{ item.goodsSpecs }} -->
      <!-- <el-input v-model="item.goodsSpecs" placeholder="选择品名联动" /> -->
      <!-- </td>
              <td>
                {{ item.goodsProduct }} -->
      <!-- <el-input v-model="item.goodsProduct" placeholder="选择品名联动" /> -->
      <!-- </td>
              <td>
                {{ item.goodsWeights }} -->
      <!-- <el-input v-model="item.goodsWeights" placeholder="选择品名联动" /> -->
      <!-- </td>
              <td width="46px">
                <div style="width:36px">
                  <i class="icons el-icon-remove-outline" @click="deleteRow(inde)" />
                </div>
              </td>
            </tr>
            <tr>
              <td v-if="ruleForm.agentSupplierGoodsList.length === 0" colspan="6">
                没有数据，请添加！
              </td>
            </tr>
          </table>
        </div>
      </el-form-item> -->
    </el-form>
    <div class="buttonCombination">
      <el-button size="small" plain class="widen-button" @click="cancelForm">
        取消
      </el-button>
      <el-button size="small" type="primary" class="widen-button" :loading="$store.state.loading" @click="submitForm('ruleForm')">
        确定
      </el-button>
    </div>
    <ChooseMatterWeight ref="chooseMatterWeight" :selected-arr="ruleForm.agentSupplierGoodsList" :show-radio="false" @getCheckId="getCheckId" />
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload'
import Table from '@/components/Table'
import ChooseMatterWeight from '@/components/ChooseMatterWeight'
import { formPattern, rules } from '@/unit/matchFormRegex'
import { compactServiceGetCompactByType } from '@/http/agentApi/enterpriseInformation'
import { agentSupplierAddAndUpdate, agentSupplierDetails } from '@/http/agentApi/crmManagement'
import { warehouseList, getSelectAreaTree, getGoodsList } from '@/http/agentApi/common'
export default {
  components: { FileUpload, Table, ChooseMatterWeight },
  data() {
    var agentSupplierPaymentConfigList = (rule, value, callback) => {
      if (this.ruleForm.agentSupplierPaymentConfigList && this.ruleForm.agentSupplierPaymentConfigList.length === 0) {
        callback(new Error('请填写一个付款配置'))
        return
      }
      callback()
    }
    var cooContractPath = (rule, value, callback) => {
      if (!this.ruleForm.cooContractPath) {
        callback(new Error('请上传'))
        return
      }
      callback()
    }
    return {
      ruleForm: {
        allAddress: [],
        agentSupplierGoodsList: [],
        agentSupplierPaymentConfigList: []
      },
      compactServiceListArr: [],
      custTemplateArr1: [],
      custTemplateArr2: [],
      warehouseListArr: [],
      rules: {
        partnerCode: [
          { required: true, message: '请输入合作企业', trigger: 'change' }
        ],
        agentSupplierPaymentConfigList: [{ required: true, validator: agentSupplierPaymentConfigList, trigger: ['blur', 'change'] }],
        deliveryInventoryName: [[...rules.selectiveType]],
        plantType: [...rules.selectiveType],
        firmAddress: [...rules.notEmpty],
        allAddress: [...rules.notEmpty],
        contacts: [
          ...rules.name,
          { ...formPattern.allChinese }
        ],
        contactPhone: [...rules.contactInformation],
        deliveryAddr: [...rules.name],
        partnerAddr: [...rules.name],
        deliverWarehouseId: [...rules.name],
        settleDate: [
          { required: true, message: '请填写数字，范围在1~28中间', trigger: 'blur' }
        ],
        partnerTemplateId: [{ required: true, message: '请选择上游协议模板', trigger: 'change' }],
        custTemplateId: [{ required: true, message: '请选择下游协议模板', trigger: 'change' }],
        cooContractPath: [
          { required: true, validator: cooContractPath, trigger: 'change' }
        ]
      },
      allCityOption: [],
      pnameId: '',
      changePnameIndex: 0,
      // 付款配置配置项
      itemData: [
        { label: '付款日期(每月)', prop: 'paymentDate', width: 100 },
        { label: '付款比例', prop: 'paymentRate', width: 100 },
        { label: '操作', prop: 'deleteRow', width: 40 }
      ],
      // 代采商品配置项
      itemDataGoods: [
        { label: '品名', prop: 'goodsName', width: 200 },
        { label: '材质', prop: 'goodsMaterials', width: 200 },
        { label: '规格', prop: 'goodsSpecs', width: 200 },
        { label: '件重（吨）', prop: 'goodsWeights', width: 200 }
      ],
      pnameArr: [],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/crmManagement/cooperativeEnterprise', name: '合作企业' }, { path: '', name: '新增合作企业' }]
    }
  },
  created() {
    this.init()
  },
  methods: {
    // 获取交收仓库名称
    getdeliverWarehouseName(value) {
      this.warehouseListArr.forEach(item => {
        if (value === item.id) {
          this.ruleForm.deliveryInventoryName = item.warehouseName
          this.ruleForm.inventoryType = item.warehouseLevel
          this.ruleForm.deliveryType = item.warehouseProp
          this.ruleForm.deliveryAddr = item.placeDetailAddr
          this.ruleForm.allAddress = [item.placeProvince, item.placeCity, item.placeTown]
        }
      })
    },
    // 初始化获取数据
    init() {
      // 获取客户端合同模板  x下游传02   上游传06
      compactServiceGetCompactByType(['06', '02'], res => {
        this.custTemplateArr1 = res.data.filter(item => item.compactType === '06')
        this.custTemplateArr2 = res.data.filter(item => item.compactType === '02')
      })
      // 获取票品名
      getGoodsList(res => {
        // 通过code为六位数的就是品名  取到这个就是第三级
        this.pnameArr = res.data.filter(item => item.code.length === 6)
      }, () => { }, '')
      // 获取所有的城市
      getSelectAreaTree('00', res => {
        this.allCityOption = [...res.data]
      })
      // 如果有ID带过来就请求数据渲染页面
      if (this.$route.query.id) {
        agentSupplierDetails(this.$route.query.id, res => {
          this.ruleForm = { ...res.data }
          console.log(this.ruleForm)
          this.ruleForm.deliveryType = this.ruleForm.deliveryType + ''
          this.ruleForm.plantType = this.ruleForm.plantType + ''
          this.ruleForm.inventoryType = this.ruleForm.inventoryType + ''
          if (this.ruleForm.deliverWarehouseId) this.querySearch(this.ruleForm.deliveryInventoryName)
          // 渲染城市
          this.ruleForm.firmAddress = [this.ruleForm.partnerProvince, this.ruleForm.partnerCity, this.ruleForm.partnerCounty]
          this.ruleForm.allAddress = [this.ruleForm.deliveryProvince, this.ruleForm.deliveryCity, this.ruleForm.deliveryCounty]
        })
      }
    },
    // 当输入结算日期时的联动
    getSettleDate(e) {
      this.ruleForm.settleDate = e > 28 ? 28 : e < 1 ? '' : Math.trunc(e)
    },
    querySearch(queryString) {
      warehouseList(queryString, res => {
        this.warehouseListArr = [...res.data]
      })
    },
    // 得到选中的品名的数据
    getCheckId(value) {
      const newArr = this.ruleForm.agentSupplierGoodsList.concat(value || [])
      this.ruleForm.agentSupplierGoodsList = this.unique(newArr)
      for (let index = 0; index < this.ruleForm.agentSupplierGoodsList.length; index++) {
        const element = this.ruleForm.agentSupplierGoodsList[index]
        this.$set(element, 'goodsMaterials', element.materials || element.goodsMaterials)
        this.$set(element, 'goodsProduct', element.productions || element.goodsProduct)
        this.$set(element, 'goodsSpecs', element.specs || element.goodsSpecs)
        this.$set(element, 'goodsWeights', element.weights || element.goodsWeights)
        this.$set(element, 'goodsWeightsId', element.goodsWeightsId || element.id)
      }
    },
    unique(arr) {
      const res = new Map()
      return arr.filter((a) => !res.has(a.id) && res.set(a.id, 1))
    },
    // 删除单个的
    deleteRow(index, row) {
      if (row) {
        this.ruleForm.agentSupplierPaymentConfigList.splice(index, 1)
        return
      }
      this.ruleForm.agentSupplierGoodsList.splice(index, 1)
    },
    // 改变品名
    getPnameId(value, index) {
      this.changePnameIndex = index
      this.$set(value, 'goodsMaterials', '')
      this.$set(value, 'goodsProduct', '')
      this.$set(value, 'goodsSpecs', '')
      this.$set(value, 'goodsWeights', '')
      // 进行判断  如果品名是我们库里有的就弹窗  没有的就输入
      const flag = this.pnameArr.length === 0 ? false : this.pnameArr.some(item => {
        this.$set(value, 'goodsName', item.goodsName)
        return item.code === value.goodsId
      })
      // 如果有就弹窗取数值
      if (flag) {
        this.pnameId = value.goodsId
        this.$refs.chooseMatterWeight.dialogTableVisible = true
      }
    },
    inputPaymentDate(row, num) {
      this.$set(row, 'paymentDate', row.paymentDate > 28 ? 28 : row.paymentDate < 1 ? '' : Math.trunc(row.paymentDate))
      this.ruleForm.agentSupplierPaymentConfigList.forEach((item, index) => {
        if (index !== num && Number(row.paymentDate) === Number(item.paymentDate)) {
          this.$message.error('日期不能相同')
          this.$set(row, 'paymentDate', '')
        }
      })
    },
    // 获取城市
    getCity(arr) {
      if (arr.length !== 3) return
      this.ruleForm.deliveryProvince = arr[0]
      this.ruleForm.deliveryCity = arr[1]
      this.ruleForm.deliveryCounty = arr[2]
    },
    getFirmCity(arr) {
      if (arr && arr.length !== 3) return
      this.ruleForm.partnerProvince = arr[0]
      this.ruleForm.partnerCity = arr[1]
      this.ruleForm.partnerCounty = arr[2]
    },
    // 表单部分字段校验
    validateField(field) {
      this.$refs.ruleForm.validateField(field)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.agentSupplierPaymentConfigList.length > 0) {
            let num = 0
            const flag = this.ruleForm.agentSupplierPaymentConfigList.every(item => {
              num += parseInt(item.paymentRate)
              return item.paymentRate && item.paymentDate
            })
            if (!flag) {
              this.$message.error('付款费率每一行都要填齐。')
              return
            }
            if (num !== 100) {
              this.$message.error('付款费率相加要为100')
              return
            }
          }
          // 判断代采商品的规格的每一个都需要填写
          // const flagSupplierGoods = this.ruleForm.agentSupplierGoodsList.length === 0 ? true : this.ruleForm.agentSupplierGoodsList.every(item => {
          //   return item.goodsName
          // })
          // if (!flagSupplierGoods) {
          //   this.$message.error('代采商品每一行都要填齐。')
          //   return
          // }
          agentSupplierAddAndUpdate(this.ruleForm, () => {
            this.$message.success('成功！')
            this.$router.push({
              path: '/crmManagement/cooperativeEnterprise'
            })
          })
        }
      })
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 取消
    cancelForm() {
      this.$router.back(-1)
    },
    // 合作协议
    getCooAgrFile(file) {
      this.ruleForm.cooContractPath = file.fileUrl
      this.ruleForm.cooContractName = file.fileName
      this.validateField('cooContractPath')
    },
    // 其他协议
    getOthMaterialFile(file) {
      this.ruleForm.otherFilePath = file.fileUrl
      this.ruleForm.otherFileName = file.fileName
    },
    // 新增付款配置
    addPaymentConfig() {
      if (this.ruleForm.agentSupplierPaymentConfigList.length === 28) {
        this.$message.error('最多添加28条')
        return
      }
      this.ruleForm.agentSupplierPaymentConfigList.push({})
    },
    // 新增代采
    addGoodsConfig() {
      this.$refs.chooseMatterWeight.dialogTableVisible = true
    }
  }
}
</script>

<style lang="scss">
.addCooperativeEnterprise {
  .tableBox{
      max-height: 320px;
      overflow-y: auto;
    }
  .el-form {
    .lastOne {
      width: 100%;
    }
    .el-input__inner,
    .el-select,
    .el-input,
    .el-autocomplete,
    .el-cascader {
      width: 100%;
    }
    .imageBox {
      margin: 12px 0 0 12px;
      display: inline-block;
      height: 125px;
      width: 180px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;

      span {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        text-align: center;
        line-height: 24px;
        background-color: rgba(0, 0, 0, .3);
        color: #fff;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        cursor: pointer;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }
    .choice {
      margin-left: 12px;
      color: #409EFF;
      cursor: pointer;
    }
    .icons {
      font-size: 26px!important;
      cursor: pointer;
      line-height: 40px;
    }
  }

  .buttonCombination {
    text-align: center;
  }

  .row {
    display: flex;
    align-items: center;
    p {
      flex-shrink: 0;
    }
  }

  .spread-over {
    width: 100% !important;

    .table {
      width: 100%;

      td {
        border: 1px solid #ededed;
        text-align: center !important;

        input {
          text-align: center;
        }
      }
    }
  }
}
.table-item .el-form-item__content{
  width: 84% !important;
}
</style>
